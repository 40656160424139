 <template>
  <div class="sidebar__profile">
    <div class="admin__profile">
      <div class="ui grid container vertically padded">
        <div class="fluid row">
          <div class="column">
            <div class="photo">
              <img :src="gravatar"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="admin__info">
      <div class="ui grid container vertically padded">
        <div class="fluid row">
          <div class="column">
            <form class="ui form">
              <div class="field">
                <label>Nome</label>
                <input type="text" name="name" placeholder="Name" readonly :value="admin_info.firstName">
              </div>
              <div class="field">
                <label>Email</label>
                <input type="text" name="email" placeholder="Email" readonly :value="admin_info.email">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="admin__logout ui bottom attached" >
      <div class="ui grid container vertically padded">
        <div class="two column row" @click="logout">
          <div class="ui eight wide column middle aligned content">
            <span class="label">Sair</span>
          </div>
          <div class="ui eight wide column">
            <button class="ui small basic icon right floated button">
              <i class="fas fa-sign-out-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'md5';
export default {
	name: 'MyProfile',
    data() {
      return {
        isActive: false
      }
	},
	computed: {
		admin_info() {
			let adminInfo = {
				email: '',
				id: '',
				image_path: null,
        firstName: '',
        lastName: ''
			}

			if (this.$store.getters['User/getAdminInfo']) {
				adminInfo = this.$store.getters['User/getAdminInfo']
			}

			return adminInfo
		},
    gravatar(){
      let vm = this
      let emailHash = md5(vm.admin_info.email)
      return `https://secure.gravatar.com/avatar/${emailHash}?size=100&d=mp`
    }
  },
	methods: {
		logout() {
      localStorage.clear()
      this.$router.push({ name: "login" })
			// window.location.href = '/'
		}
	}
}
</script>

<style lang="scss" scoped>
  /* Admin */
  .photo {
    display: block;
    width: 136px;
    height: 136px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }
  .sidebar__profile{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .admin__info {
    input{
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #232729 !important;
    }
  }
  .admin__logout {
    position: absolute;
    width: 100%;
    max-width: 293px;
    bottom: 0;
    border-top: 1px solid #dddddd;
    cursor: pointer;
    &:hover{
      .button {
        transform: translateX(4px);
      }
    }
    .button{
      transition: 400ms;
      border: none !important;
      box-shadow: none !important;
    }
  }
</style>

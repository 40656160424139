<template>
  <div class="ui segments sidebar__profile">
    <div>
      <div class="ui segment top attached profile__info">
        <div class="photo">
          <img
            v-if="!data.avatar_url"
            class="noPhoto"
            :src="require('@/assets/icons/random-gravatar.png')"
          />
          <img
            v-if="data.avatar_url"
            :src="data.avatar_url"
          />
        </div>
        <div class="user__info">
          <div class="artist__name">
            <h5>Nome</h5>
            <span>{{ data.name }}</span>
          </div>
          <div class="user__type">
            <h5>Tipo</h5>
            <span>{{ data.role }}</span>
          </div>
          <div class="member__since">
            <h5>Criado em</h5>
            <span>{{
              $moment(data.created_at)
                .tz($moment.tz.guess())
                .format("DD MMM YYYY")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    data: {}
  },
  data() {
    return {
      isActive: false
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.sidebar__profile {
  border: none;
  box-shadow: none;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ui.segment {
    border: none;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  /* User Info */
  .photo {
    display: inline-block;
    width: 136px;
    height: 136px;
    background-color: #888;
    border-radius: 8px;
    vertical-align: middle;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      &.noPhoto {
        opacity: 0.8;
      }
    }
  }
  .photo {
    display: block;
    width: 136px;
    height: 136px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      &.noPhoto{
        opacity: .8;
      }
    }
  }

  .profile__info {
    margin-top: 1.2rem;
  }
  .user__info {
    display: block;
    padding: 0 16px;
    margin-top: 20px;
    > div {
      margin-bottom: 0.35rem;
    }
    h5 {
      margin: 0;
      color: #9e9e9e;
    }
    span {
      font-size: 0.9rem;
      font-weight: 600;
      color: #232729;
    }
  }
  /* Tabs header */
  .profile__tabs {
    justify-self: start;
    .menu {
      margin-bottom: 0;
      .item {
        text-transform: uppercase;
        font-weight: 600;
        transition: 400ms !important;
        &.active {
          border-color: #63686b !important;
        }
      }
    }
    .ui.tab.segment {
      position: relative;
      border-radius: 0;
      border: none;
      box-shadow: none;
      margin: 0 !important;
      transition: 400ms;
      .header {
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 600;
        color: #9e9e9e;
      }
    }
  }
  /* stats */

  .profile__section {
    margin-bottom: 2rem;
  }
  .info__title {
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #666666;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #c7c7c7;
    padding: 1rem;
  }

  .profile__stats-item,
  .profile__info-item {
    position: relative;
    padding-left: 1rem;
    margin: 1rem 0;
    .item__icon {
      position: absolute;
      top: 0;
      left: 1rem;
      height: 1.3rem;
      width: 1.3rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item__title {
      font-size: 0.9rem;
      color: #9e9e9e;
      margin: 0;
    }
    .item__text {
      font-size: 0.9rem;
      color: #232729;
      margin: 0;
      font-weight: 600;
    }
  }
  .profile__stats-item {
    padding-left: 3rem;
    .item__title {
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  /* block */
  .profile__block-users {
    border-top: 1px solid #c7c7c7 !important;
    text-align: center;
  }
}
</style>

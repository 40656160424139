<template>
  <div></div>
</template>

<script>
export default {
  name: "UserSession",
  props: {
    loginRequired: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    checkTokenExpDate() {
      const vm = this;

      setTimeout(() => {
        let token = localStorage.token
          ? vm.$jwt.decode(localStorage.token)
          : null;
        if (token) {
          if (vm.$moment().unix() >= token.exp) {
            vm.validateToken();
          }
          vm.checkTokenExpDate();
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("data");
          vm.$store.state.loader = false;
          vm.$router.push({ name: "login" });
        }
      }, 1000 * 60 * 60); /* 1 hour */
    },
    validateToken() {
      const vm = this;

      let token = localStorage.token ? localStorage.token : null;
      if (token) {
        vm.$store
          .dispatch(`Register/VALIDATE_LOGIN`, localStorage.token)
          .then()
          .catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("data");
            vm.$router.push({ name: "login" });
          });
      }
    }
  },
  beforeCreate() {
    const vm = this;
    let token = localStorage.token ? localStorage.token : null;
    if (token) {
      vm.$store
        .dispatch(`Register/VALIDATE_LOGIN`, localStorage.token)
        .then()
        .catch(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("data");
          vm.$router.push({ name: "login" });
        });
    }
  },
  mounted() {
    let vm = this;
    if (vm.loginRequired && !localStorage.token) {
      vm.$router.push({ name: "login" });
    }
    if (this.$route.meta.requiresAuth) {
      this.checkTokenExpDate();
    }
  }
};
</script>

<style scoped></style>

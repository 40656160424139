<template>
  <div id="header">
    <div id="logo" @click="goToHome">
      <img
          src="@/assets/thomson-reuters-logo-graph.png"
          alt="Dashboard | Thomson Reuters"
        />
    </div>
    <div class="myProfile" @click="openUserInfoSidebar()">
      <div class="avatar">
        <img
          :src="gravatar"
        />
      </div>
      <span class="username">Olá, {{ admin_info.firstName }}</span>
    </div>
  </div>
</template>

<script>
import md5 from 'md5';
export default {
  name: "Header",
  components: {},
  computed: {
    admin_info() {
      let adminInfo = {
        email: "",
        id: "",
        image_path: null,
        firstName: "",
        lastName: "",
      };

      if (this.$store.getters["User/getAdminInfo"]) {
        adminInfo = this.$store.getters["User/getAdminInfo"];
      }

      return adminInfo;
    },
    gravatar(){
      let vm = this
      let emailHash = md5(vm.admin_info.email)
      return `https://secure.gravatar.com/avatar/${emailHash}?size=100&d=mp`
    }
  },
  data() {
    return {};
  },
  methods: {
    openUserInfoSidebar() {
      this.$bus.$emit("sidebar", { type: "my_profile" });
    },
    goToHome() {
      this.$router.push({ name: "backoffice" });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/globals/colors';
#header {
  width: 100vw;
  display: flex;
  background-color: #fff;
  height: 10vh;
  justify-content: space-between;
  border-bottom: 1px solid $color-gray-light;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 5px rgba(0,0,0,.1);
  z-index: 10;

  #logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vh;
    height: 10vh;
    padding: 10px;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
  }

  .myProfile {
    width: auto;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    cursor: pointer;
    .username {
      font-weight: 600;
      display: inline-block;
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.6);
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 30vw;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 24px;
      background-color: #4247495e;
      overflow: hidden;
      border: 1px solid #4247495e;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>

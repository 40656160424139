<template>
  <div id="menu">
    <nav>
      <router-link class="item" :to="{ name: 'backoffice dashboard' }" exact>
        <div class="item__link">
          <i class="fas fa-home item__link-icon"></i>
          <span class="item__link-text">Dashboard</span>
        </div>
      </router-link>
      <router-link class="item" :to="{ name: 'backoffice reports' }" exact>
        <div class="item__link">
          <i class="far fa-file-alt item__link-icon"></i>
          <span class="item__link-text">Relatórios</span>
        </div>
      </router-link>
      <router-link class="item" :to="{ name: 'backoffice users' }" exact>
        <div class="item__link">
          <i class="far fa-user item__link-icon"></i>
          <span class="item__link-text">Usuários</span>
        </div>
      </router-link>
      <router-link class="item" :to="{ name: 'backoffice tools' }" exact>
        <div class="item__link">
          <i class="fa fa-tools item__link-icon"></i>
          <span class="item__link-text">Ferramentas</span>
        </div>
      </router-link>
      <!-- <router-link class="item" :to="{ name: 'backoffice logs' }" exact>
        <div class="item__link">
          <i class="item__link-icon">
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g id="folder"><polygon style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;" points="25 12 25 7 3 7 3 25 25 25 29 12 25 12"/><polygon style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;" points="25 25 3 25 7 12 29 12 25 25"/></g></svg>
          </i>
          <span class="item__link-text">Logs</span>
        </div>
      </router-link> -->
    </nav>
  </div>
</template>

<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/globals/colors";
#menu {
  height: 80vh;
  display: flex;
  background-color: #fff;
  min-width: 10vh;
  padding: 10px 50px 10px 40px;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: calc(50% - 35vh);
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 5;
  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 50vh; */

    .item {
      display: flex;
      width: 100%;
      height: 6vh;
      position: relative;
      align-items: center;
      justify-content: center;

      &.active,
      &:hover {
        .item__link-icon {
          svg {
            fill: $color-secondary !important;
          }
          #document {
            polyline,
            line {
              stroke: $color-secondary !important;
            }
          }
          #folder {
            polygon {
              stroke: $color-secondary !important;
            }
          }
        }
        .item__link-text {
          color: $color-secondary;
        }
      }
      .item__link {
        display: flex;
        width: 100%;
      }
      .item__link-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        svg {
          width: 100%;
          height: auto;
        }
        color: #eb791e;
      }
      .item__link-text {
        font-size: 1rem;
        color: $color-primary;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  #menu {
    width: 36px;
    padding: 10px;
    min-width: auto;
    justify-content: flex-start;
    overflow: hidden;
  }
}
</style>

 <template>
    <div class="ui segments sidebar__edit_admin">
      <div>
        <div class="ui segment top attached admin__profile">
          <div class="ui grid container">
            <div class="fluid row">
              <div class="column">
                <div class="photo">
                  <div class="dimmer_upload_photo">
                    <i class="fas fa-upload"></i>
                    <span>Upload</span>
                  </div>
                  <label for="image-input">
                    <img v-if="!data.image_path" class="noPhoto" :src="require('@/assets/icons/no-avatar.svg')"/>
                    <img v-if="data.image_path" :src="data.image_path"/>
                  </label>
                  <input type="file" id='image-input' @change="onFileChanged">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui segment admin__info">
          <div class="ui grid container">
            <div class="fluid row">
              <div class="column">
                <form class="ui form" id="edit-admin" @submit.prevent="handleSubmit">
                  <div class="field">
                    <label>Nome</label>
                    <input v-model="data.name" type="text" name="name" placeholder="Name" required>
                  </div>
                  <div class="field">
                    <label>Email</label>
                    <input v-model="data.email" type="email" name="email" placeholder="Email" readonly>
                  </div>
                  <div class="field">
                    <label>Tipo</label>
                    <select name="admin_type" v-model="data.admin_type" class="ui dropdown" :class="{'disabled': !isSuperAdmin || isSuperAdmin && isEditingYourself }">
                      <option
                        v-for="(adminType, index) in adminTypes"
                        :key="index" :value="adminType.type"
                        :selected="adminType.type === data.admin_type"
                        :disabled="adminType.type === 'SUPER_ADMIN'">
                        {{adminType.type}}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="ui segment admin__edit__actions" v-if="showByRole">
          <div class="ui grid container">
            <div class="two column row">
              <div class="ui eight wide column">
                <button @click="cancelForm" class="ui fluid button">Cancelar</button>
              </div>
              <div class="ui eight wide column">
                <button :disabled="sendingForm" type="submit" form="edit-admin" class="ui fluid secondary button">Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui basic left aligned bottom attached segment admin__delete" v-if="!isEditingYourself && !isViewer">
        <div class="ui grid two column container" @click="toggleDeleteUserModal">
          <div class="ui thirteen wide column middle aligned content">
            <span class="label">Deletar usuário}</span>
          </div>
          <div class="ui three wide column trashbin__icon">
            <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path fill="#232729" class="svg__trashbin" d="m116.06 170.97 22 292.23c2.11 27.3 24.86 48.8 50.99 48.8l133.82 0c26.11 0 48.88-21.5 50.97-48.8l22.1-292.23-279.88 0zm84.58 303.06c.25 5.96-4.28 10.67-10.07 10.67-5.8 0-10.67-4.79-11-10.67l-12.27-249.14c-.43-8.07 4.79-14.62 11.59-14.62 6.8 0 12.52 6.55 12.84 14.62l8.91 249.14zm65.87 0c0 5.96-4.8 10.67-10.51 10.67-5.71 0-10.51-4.79-10.51-10.67l-1.68-249.14c-.08-8.07 5.39-14.62 12.19-14.62 6.8 0 12.27 6.55 12.19 14.62l-1.68 249.14zm65.84 0c-.25 5.96-5.2 10.67-11 10.67-5.79 0-10.24-4.79-10.07-10.67l8.9-249.14c.25-8.07 6.05-14.62 12.85-14.62 6.79 0 12.02 6.55 11.59 14.62l-12.27 249.14z"/>
              <path fill="#232729" class="svg__trashbin__lid" d="m415.01 156.51-318.02 0 0 0c-1.51-17.72 8.99-32.26 23.43-32.26l271.24 0c14.36 0 24.86 14.62 23.35 32.26l0 0zm-101.65-20.32-114.72 0-.19-6.55c-.65-20.58 12.27-37.64 28.9-37.64l57.38 0c16.55 0 29.47 17.06 28.9 37.56l-.27 6.63z"/>
            </svg>
          </div>
        </div>
      </div>

      <div class="ui mini modal" id="edit-admin-modal-confirm">
        <div class="content">
          <div class="description">Usuário atualizado</div>
        </div>
        <div class="actions">
          <div class="ui button cancel black">OK</div>
        </div>
      </div>
      <div class="ui mini modal" id="edit-admin-modal-error">
        <div class="content">
          <div class="description">Erro ao atualizar o usuário</div>
        </div>
        <div class="actions">
          <div class="ui button cancel black">OK</div>
        </div>
      </div>
      <div class="ui mini modal" id="edit-admin-modal-delete-user">
        <div class="header">Você tem certeza?</div>
        <div class="content">
          <div class="description">
            Esta ação não poderá ser desfeita!
          </div>
        </div>
        <div class="actions">
          <div class="ui button cancel">Cancelar</div>
          <div class="ui button black" @click="deleteUser">Confirmar</div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'EditAdmin',
    props: {
      data: {}
    },
    data() {
      return {
        isActive: false,
        sendingForm: false
      }
    },
    mounted() {
      // eslint-disable-next-line
      $('#edit-admin-modal-confirm').modal()
      // eslint-disable-next-line
      $('#edit-admin-modal-error').modal()
      // eslint-disable-next-line
		  $('#edit-admin-modal-delete-user').modal()
    },
    computed: {
      adminTypes() {
        if (this.$store.state.User.admin_types && this.data.admin_type) {
          return this.$store.state.User.admin_types
        }
        return []
      },
      showByRole() {
        let vm = this
        return vm.isSuperAdmin || vm.isAdmin || vm.isEditingYourself
      },
      isEditingYourself() {
        return this.data.id === this.$store.state.User.info.id
      },
      isSuperAdmin() {
        return this.$store.state.User.info.admin_type == 'SUPER_ADMIN'
      },
      isAdmin() {
        return this.$store.state.User.info.admin_type == 'ADMIN'
      },
      isViewer() {
        return this.$store.state.User.info.admin_type == 'VIEWER'
      }
    },
    methods: {
      handleSubmit() {
        const vm = this

        vm.sendingForm = true

        const formData = new FormData()
        let endpoint = ''

        if (vm.isEditingYourself) {
          endpoint = 'UPDATE_PROFILE'
        } else  {
          endpoint = 'UPDATE_ADMIN_USER'
          formData.append('admin_id', vm.data.id)
        }

        formData.append('admin_info[name]', vm.data.name)
        if (vm.data.image) { formData.append('admin_info[image]', vm.data.image) }

        if (!vm.isEditingYourself && vm.isSuperAdmin) {
          formData.append('admin_info[admin_type]', vm.data.admin_type.toLowerCase())
        }

        vm.$store.dispatch(`User/${endpoint}`, formData)
        .then(() => {
          vm.$bus.$emit('sidebar', {type: 'edit_admin'})
          vm.$bus.$emit('getUserList')
          vm.toggleConfirmModal()
        }).catch(() => {
          vm.toggleErrorModal()
        })
        .finally(()=> {
          vm.sendingForm = false
        })
      },
      cancelForm() {
        this.$bus.$emit('sidebar', {type: 'edit_admin'})
      },
      toggleConfirmModal() {
        // eslint-disable-next-line
        $('#edit-admin-modal-confirm').modal('toggle')
      },
      toggleErrorModal() {
        // eslint-disable-next-line
        $('#edit-admin-modal-error').modal('toggle')
      },
      toggleDeleteUserModal() {
        // eslint-disable-next-line
        $('#edit-admin-modal-delete-user').modal('toggle')
      },
      deleteUser() {
        const vm = this

        vm.$store.dispatch('User/DELETE_ADMIN_USER', vm.data.id)
        .then(() => {
          vm.$bus.$emit('sidebar', {type: 'edit_admin'})
          vm.$bus.$emit('getUserList')
          vm.toggleDeleteUserModal()
        })
      },
      onFileChanged (event) {
        const vm = this
        const input = event.target
        if (input.files && input.files[0]) {
          vm.data.image = event.target.files[0]
          const reader = new FileReader()
          reader.onload = (e) => { vm.data.image_path = e.target.result }
          reader.readAsDataURL(input.files[0])
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
/* Admin */
  .photo {
    position: relative;
    display: inline-block;
    width: 136px;
    height: 136px;
    background-color: #888;
    border-radius: 8px;
    vertical-align: middle;
    overflow: hidden;
    .dimmer_upload_photo {
      border-radius: 8px;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0);
      color: #fff;
      z-index: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      transition: .3s ease;
    }
    &:hover {
      .dimmer_upload_photo {
        visibility: visible;
        opacity: 1;
        background-color: rgba(0,0,0,0.6);
      }
    }
    label {
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
    .noPhoto {
      opacity: .8;
    }
  }
  .sidebar__edit_admin{
    position: relative;
    border: none;
    box-shadow: none;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ui.segment{
        border: none;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
    }
  }
  .admin__data {
    display: inline-block;
    vertical-align: middle;
    padding: 0 16px;
    > div {
        margin-bottom: 0.35rem;
    }
    h5 {
      margin: 0;
      color: #9e9e9e
    }
    span {
      font-size: .9rem;
      font-weight: 600;
      color: #232729;
    }
  }
  .admin__info {
    justify-self: start;
    input {
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #232729 !important;
    }
  }
  #image-input {
    display: none;
  }
  /* Delete */
  .admin__delete {
    border-top: 1px solid #c7c7c7 !important;
    text-align: center;
    cursor: pointer;
    svg{
      fill-opacity: 0.6;
    }
    .svg__trashbin__lid{
      transition: 400ms;
    }
    &:hover{
      .svg__trashbin__lid{
        transform: translate(-35px, 0px) rotateZ(-10deg);
      }
    }
  }
</style>

<template>
  <div class="backoffice">
    <UserSession :loginRequired="loginRequired"></UserSession>
    <div class="ui dimmer full__dimmer" :class="{ active: loading }">
      <div class="ui loader"></div>
    </div>
    <slot></slot>
    <Header v-if="$route.meta.showMenu" />
    <Sidebar v-if="$route.meta.showMenu" />
    <Menu v-if="$route.meta.showMenu" />
  </div>
</template>

<script>
import UserSession from "../../components/UserSession";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Menu from "../../components/Menu";

export default {
  name: "BackofficePage",
  components: {
    UserSession,
    Sidebar,
    Header,
    Menu,
  },
  data() {
    return {
      loading: true,
    };
  },
  props: {
    loginRequired: {
      type: Boolean,
      default: true, /* CODE-SMELL: NEED TO CHANGE TO 'true', use 'false' just when need to test pages without session auth */
    },
  },
  methods: {},
  created() {
    let vm = this;
    vm.loading = true;
    if (!vm.$store.state.User.info && localStorage.token) {
      vm.$store
        .dispatch("User/SET_ADMIN_USER_INFO", localStorage.token)
        .finally(() => {
          vm.loading = false;
        });
    } else {
      vm.loading = false;
    }

    if (!vm.$store.state.User.admin_types.length && localStorage.token) {
      // vm.$store.dispatch("User/GET_ADMIN_TYPES");
    }
  },
  beforeMount() {},
};
</script>

<style scoped>
.full__dimmer {
  z-index: 1001;
}
</style>

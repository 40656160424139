<template>
  <div class="sidebar__profile">
    <div class="admin__profile">
      <div class="ui grid container vertically padded">
        <div class="fluid row">
          <div class="column">
            <div class="photo">
              <div class="dimmer_upload_photo">
                <i class="fas fa-upload"></i>
                <span>Upload</span>
              </div>
              <label for="image-input">
                <div v-if="!image_path" class="noPhoto"></div>
                <div
                  v-if="image_path"
                  class="photo"
                  :style="{ backgroundImage: 'url(' + image_path + ')' }"
                ></div>
              </label>
              <input type="file" id="image-input" @change="onFileChanged" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin__info">
      <div class="ui grid container vertically padded">
        <div class="fluid row">
          <div class="column">
            <form
              class="ui form"
              id="create-admin"
              @submit.prevent="handleSubmit"
            >
              <div class="field">
                <label>Nome</label>
                <input
                  v-model="name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
              </div>
              <div class="field">
                <label>Email</label>
                <input
                  v-model="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div class="field">
                <label>Tipo</label>
                <div class="ui fluid search selection dropdown user-type">
                  <input type="hidden" name="user-type" />
                  <i class="dropdown icon"></i>
                  <div class="default text"></div>
                  <div class="menu"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="admin__edit__actions">
      <div class="ui grid container vertically padded">
        <div class="two column row">
          <div class="ui eight wide column">
            <button @click="cancelForm" class="ui fluid button">
              Cancelar
            </button>
          </div>
          <div class="ui eight wide column">
            <button
              :disabled="sendingForm"
              type="submit"
              form="create-admin"
              class="ui fluid secondary button"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="ui mini modal" id="create-admin-modal-confirm">
      <div class="content">
        <div class="description">Criado com sucesso</div>
      </div>
      <div class="actions">
        <div class="ui button cancel black">OK</div>
      </div>
    </div>
    <div class="ui mini modal" id="create-admin-modal-error">
      <div class="content">
        <div class="description">Ocorreu algum problema...</div>
      </div>
      <div class="actions">
        <div class="ui button cancel black">OK</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateAdmin",
  data() {
    return {
      isActive: false,
      name: null,
      email: null,
      admin_type: null,
      sendingForm: false,
      image_path: null,
      image: null,
    };
  },
  mounted() {
    // eslint-disable-next-line
    $(".ui.dropdown.user-type").dropdown({
      placeholder: "",
      values: this.adminTypes,
      onChange: (value) => {
        this.admin_type = value;
      },
    });

    // eslint-disable-next-line
    $("#create-admin-modal-confirm").modal();
    // eslint-disable-next-line
    $("#create-admin-modal-error").modal();
  },
  computed: {
    adminTypes() {
      if (this.$store.state.User.admin_types) {
        return this.$store.state.User.admin_types.map((item) => {
          item.name = item.type;
          item.value = item.type;
          if (item.type == "SUPER_ADMIN") {
            item.disabled = true;
          }
          return item;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    handleSubmit() {
      const vm = this;

      if (!vm.admin_type) {
        return;
      }
      vm.sendingForm = true;

      const formData = new FormData();
      formData.append("name", vm.name);
      formData.append("email", vm.email);
      formData.append(
        "password",
        Math.floor(100000 + Math.random() * 900000)
      ); /* CODE-SMELL: Create a random password with six numbers */
      formData.append("admin_type", vm.admin_type.toLowerCase());
      if (vm.image) {
        formData.append("image", vm.image);
      }

      vm.$store
        .dispatch("Register/CREATE_USER", formData)
        .then(() => {
          vm.name = "";
          vm.email = "";
          vm.admin_type = "";
          vm.image_path = "";
          vm.image = null;
          vm.sendingForm = false;
          // eslint-disable-next-line
          $(".ui.dropdown.user-type").dropdown("restore defaults");
          vm.$bus.$emit("sidebar", { type: "create_admin" });
          vm.$bus.$emit("getUserList");
          vm.toggleConfirmModal();
        })
        .catch((_error) => {
          vm.sendingForm = false;
          vm.toggleErrorModal();
        });
    },
    cancelForm() {
      const vm = this;
      vm.name = "";
      vm.email = "";
      vm.admin_type = "";
      vm.image_path = "";
      vm.image = null;
      // eslint-disable-next-line
      $(".ui.dropdown.user-type").dropdown("restore defaults");
      vm.$bus.$emit("sidebar", { type: "create_admin" });
    },
    toggleConfirmModal() {
      // eslint-disable-next-line
      $("#create-admin-modal-confirm").modal("toggle");
    },
    toggleErrorModal() {
      // eslint-disable-next-line
      $("#create-admin-modal-error").modal("toggle");
    },
    onFileChanged(event) {
      const vm = this;
      const input = event.target;
      if (input.files && input.files[0]) {
        vm.image = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          vm.image_path = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Admin */
.photo {
  position: relative;
  display: inline-block;
  width: 136px;
  height: 136px;
  background-color: #eee;
  border-radius: 8px;
  vertical-align: middle;
  overflow: hidden;
  .dimmer_upload_photo {
    border-radius: 8px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: 0.3s ease;
  }
  &:hover {
    .dimmer_upload_photo {
      visibility: visible;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  label {
    .photo {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      cursor: pointer;
    }
    .noPhoto {
      background-image: url('~@/assets/icons/random-gravatar.png');
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center top;
      cursor: pointer;
    }
  }
}
.sidebar__profile {
  position: relative;
  width: 100%;
  height: 100%;
}
.admin__data {
  display: inline-block;
  vertical-align: middle;
  padding: 0 16px;
  > div {
    margin-bottom: 0.35rem;
  }
  h5 {
    margin: 0;
    color: #9e9e9e;
  }
  span {
    font-size: 0.9rem;
    font-weight: 600;
    color: #232729;
  }
}
.admin__info {
  input {
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #595959 !important;
  }
}
.admin__logout {
  position: absolute;
  width: 100%;
  max-width: 293px;
  bottom: 0;
}
#image-input {
  display: none;
}
</style>

<template>
  <div>
    <div
      class="sidebar__dimmer"
      :class="{ visible: isActive }"
      @click="toggleSidebar()"
    ></div>
    <div class="sidebar__right" :class="{ active: isActive }">
      <div class="sidebar__header">
        <h1>Perfil</h1>
        <div class="sidebar_btn-close" @click="toggleSidebar()">
          <img src="@/assets/icons/close.svg" />
        </div>
      </div>
      <div class="sidebar__content">
        <Profile v-if="type == 'profile'" :data="data" />
        <MyProfile v-if="type == 'my_profile'" :data="data" />
        <CreateAdmin v-if="type == 'create_admin'" />
        <EditAdmin v-if="type == 'edit_admin'" :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import Profile from "./Sidebar/Profile";
import MyProfile from "./Sidebar/MyProfile";
import CreateAdmin from "./Sidebar/CreateAdmin";
import EditAdmin from "./Sidebar/EditAdmin";

export default {
  name: "Sidebar",
  components: {
    Profile,
    MyProfile,
    CreateAdmin,
    EditAdmin,
  },
  data() {
    return {
      isActive: false,
      route: this.$route.name,
      data: {
        user_info: {},
      },
      type: "my_profile",
    };
  },
  methods: {
    toggleSidebar() {
      this.isActive = !this.isActive;
    },
  },
  created() {
    const vm = this;
    vm.$bus.$on("sidebar", (payload) => {
      if (payload.data) vm.data = payload.data;
      vm.type = payload.type;
      vm.toggleSidebar();
    });
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.sidebar__dimmer {
  display: flex;
  position: fixed;
  top: 10vh !important;
  width: 100vw;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  animation-duration: 0.5s;
  transition: opacity 0.5s linear;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
  pointer-events: none;
  &.visible {
    pointer-events: auto;
    opacity: 1;
  }
}

.sidebar__right {
  position: fixed;
  top: 10vh;
  right: -342px;
  width: 342px;
  height: calc(100vh - 10vh);
  padding: 24px;
  background-color: #fff;
  border-left: 1px solid rgba(115, 115, 115, 0.3);
  transition: right 0.3s ease-in-out;
  overflow: auto;
  z-index: 1001;
  &.active {
    right: 0;
  }
}

.sidebar__header {
  display: flex;
  align-items: center;
  height: 35px;
  position: relative;
  justify-content: space-between;
  h1 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: #232729;
    letter-spacing: 0.2px;
  }
}

.sidebar__content {
  height: calc(100% - 35px);
}

.sidebar_btn-close {
  display: flex;
  cursor: pointer;
  img {
    max-width: 20px;
    height: auto;
  }
}
</style>
